<template>
  <sm-shopping-layout>
    <div class="sm-product-screen">
      <div class="sm-product-cover">
        <img v-if="!scannedProductCoverFirstThumbnailUrl" src="img/placeholder.svg" alt="Blank image">
        <img v-else :src="scannedProductCoverFirstThumbnailUrl" alt="Article cover image" />
      </div>

      <div class="sm-product-name-container">
        <h1 class="sm-product-name">{{ scannedProduct.name }}</h1>
      </div>

      <div class="sm-product-description-container">
        <span class="sm-product-description" v-html="scannedProduct.description"></span>
      </div>

      <div class="sm-product-information-container">
        <h3 class="sm-product-information-label">Artikelinformationen</h3>

        <ion-grid class="sm-product-information">
          <ion-row class="sm-product-information__vpe ion-justify-content-start"
                   v-if="scannedProduct.customFields && scannedProduct.customFields.UnitsPerPackage && scannedProduct.customFields.UnitsPerPackage !== ''">
            <ion-col size="auto">
              <ion-icon class="sm-product-information__vpe-icon" :icon="alertCircle" color="tertiary" ></ion-icon>
              VPE gewünscht? Dann {{ scannedProduct.customFields.UnitsPerPackage }} Stück bestellen
            </ion-col>
          </ion-row>

          <ion-row v-if="scannedProduct.productNo">
            <ion-col size="5">Produkt-Nr.:</ion-col>
            <ion-col size="7" v-if="scannedProduct.customProductNo">{{ scannedProduct.productNo }} ({{ scannedProduct.customProductNo }})</ion-col>
            <ion-col size="7" v-else>{{ scannedProduct.productNo }}</ion-col>
          </ion-row>

          <ion-row v-if="scannedProduct.customProductNumber && scannedProduct.customProductNumber[0]">
            <ion-col size="5">Kunden-Produkt-Nr.:</ion-col>
            <ion-col size="7">{{ scannedProduct.customProductNumber[0] }}</ion-col>
          </ion-row>

          <ion-row v-if="scannedProduct.manufacturerName">
            <ion-col size="5">Hersteller:</ion-col>
            <ion-col size="7">{{ scannedProduct.manufacturerName }}</ion-col>
          </ion-row>

          <ion-row v-if="scannedProduct.manufacturerNo">
            <ion-col size="5">Hersteller-Nr.:</ion-col>
            <ion-col size="7">{{ scannedProduct.manufacturerNo }}</ion-col>
          </ion-row>

          <ion-row v-if="scannedProduct.ean">
            <ion-col size="5">EAN:</ion-col>
            <ion-col size="7">{{ scannedProduct.ean }}</ion-col>
          </ion-row>

          <ion-row v-if="scannedProductPZN">
            <ion-col size="5">PZN:</ion-col>
            <ion-col size="7">{{ scannedProductPZN }}</ion-col>
          </ion-row>

          <p
              v-if="getDeliveryTime(scannedProduct)"
              class="sm-line-item-delivery-time"
              :class="getDeliveryTime(scannedProduct)['color']">
            <span>.</span>
            <span>{{ getDeliveryTime(scannedProduct)['label'] }}</span>
          </p>
        </ion-grid>
      </div>

      <div v-if="scannedProductWebshopProperties.length > 0" class="sm-product-properties-container">
        <h3 class="sm-product-properties-label">Eigenschaften</h3>

        <ul class="sm-product-properties">
          <li v-for="(property, index) in scannedProductWebshopProperties" :key="index">{{ property }}</li>
        </ul>
      </div>
    </div>
  </sm-shopping-layout>
</template>

<script lang="javascript">
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import SmShoppingLayout from "@/components/layout/SmShoppingLayout";
import ScreenLoadingMixin from '@/mixins/screen-loading.mixin';
import CommonMixin from '@/mixins/common.mixin';
import ScannedProductMixin from '@/mixins/scanned-product.mixin';
import {useRouter} from "vue-router";
import { alertCircle } from 'ionicons/icons';



export default defineComponent({
  name: 'ScannedProduct',

  components: {
    SmShoppingLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon
  },

  mixins: [ScreenLoadingMixin, CommonMixin, ScannedProductMixin],

  setup() {
    const router = useRouter();
    return { router, alertCircle };
  },

  data () {
    return {
    }
  },

  // created: async function(){
  //   this.customProductNumber = await this.getCustomProductNumber();
  // },

  ionViewWillEnter() {
    this.setHeaderTitle('Gescannter Artikel');
  },

  async mounted() {
    //TODO for testing
    // this.loadProductFromApi('4ab762a72716423a9836f60d54e39b40'); // there is image
    // this.loadProductFromApi('5f50cc057d01480da4d9b92a421aba2b'); // there is no image
   // this.customProductNumber = await this.getCustomProductNumber();
  },

  computed: {
    ...mapGetters('product', ['getScannedProduct'])
  },

  methods: {
    ...mapMutations('common', ['setHeaderTitle']),

    getDeliveryTime(product) {
      if (!product) {
        return null;
      }

      const deliveryTime = product.deliveryTime;

      if (!deliveryTime) {
        return null;
      } else if (deliveryTime.max < 4) {
        return {
          color: 'text-success',
          label: `Lieferbar in ${deliveryTime.min}-${deliveryTime.max} Werktagen`
        }
      } else if (deliveryTime.max < 15) {
        return {
          color: 'text-warning',
          label: `Lieferbar in ${deliveryTime.min}-${deliveryTime.max} Werktagen`
        }
      } else {
        const deliveryMinWeek = Math.round(deliveryTime.min / 7);
        const deliveryMaxWeek = Math.round(deliveryTime.max / 7);

        return {
          color: 'text-danger',
          label: `Lieferbar in ${deliveryMinWeek}-${deliveryMaxWeek} Wochen`
        }
      }
    }
  }
})
</script>

<style scoped>
  .sm-product-cover {
    text-align: center;
    margin-top: 1rem;
  }

  .sm-product-cover img {
    max-height: 40vh;
  }
  .sm-product-information__vpe-icon {
    margin-right: 4px;
  }

  .sm-product-information__vpe-icon ion-icon {
    font-size: 55px;
  }

  .sm-product-description-container {
    margin-bottom: 1.5rem;
  }

  .sm-product-information-container ion-grid {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  .sm-product-information-container ion-grid ion-col {
    padding-inline-start: 0;
    padding-inline-end: 10px;
  }

  .sm-product-information-label,
  .sm-product-properties-label {
    font-size: 14px;
    font-weight: bold;
  }

  .sm-product-properties {
    padding-left: 1.5rem;
  }

  .sm-product-properties li {
    padding-bottom: 0.5rem;
  }

  .sm-line-item-delivery-time {
    position: relative;
  }

  .sm-line-item-delivery-time span:first-child {
    position: absolute;
    top: -20%
  }

  .sm-line-item-delivery-time span:last-child {
    margin-left: 0.5rem;
  }
</style>
